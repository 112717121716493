import React from "react"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import NotationSingle from "../../../../../components/notation-single"
import FeedbackHeaderBool from "../../../../../components/feedback-header-bool"
import PaperStack from "../../../../../components/paper-stack"
import { getCourseNavigation } from "../../../../../store/courses"

const Page = () => {
  const { booleanAnswerIsValid } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/versteckte-ordnung/01-gedicht-erkennen/maisonntag"
        />
      }
    >
      <Seo title="Die versteckte Ordnung der Gedichte" />
      <Box>
        <Stack>
          <FeedbackHeaderBool
            confirmed={booleanAnswerIsValid({
              courseId: "versteckte-ordnung",
              chapterId: "01-gedicht-erkennen",
              taskId: "im-sueden-sommerlich",
            })}
            title="Das ist kein Gedicht"
          />
          <Paragraph>
            Es handelt sich um den Wetterbericht vom 4. Juli 2020. Trotzdem gibt
            es in dem Text einige Dinge, die auch in einem Gedicht vorkommen
            könnten.
          </Paragraph>
          <Paragraph>
            Einige der Wörter beginnen mit ähnlichen Anlauten. Das gibt dem Text
            eine besondere Melodie:
          </Paragraph>
          <PaperStack>
            <Paragraph>
              Am <NotationSingle type="circle">W</NotationSingle>ochenende steht
              ein eher kühler, <NotationSingle type="circle">w</NotationSingle>
              indiger und <NotationSingle type="circle">w</NotationSingle>
              olkiger Nord
              <NotationSingle type="circle">w</NotationSingle>esten einem{" "}
              <NotationSingle type="circle">s</NotationSingle>ommerlichen{" "}
              <NotationSingle type="circle">S</NotationSingle>üden gegenüber. In
              der neuen <NotationSingle type="circle">W</NotationSingle>oche ist
              es zunächst auch im{" "}
              <NotationSingle type="circle">S</NotationSingle>üden kühler,
              anschließend geht es aber beim
              <NotationSingle type="circle">W</NotationSingle>etter insgesamt
              bergauf.
            </Paragraph>
          </PaperStack>
          <Paragraph>
            Auch die Gegensätzlichkeit von Süden und Norden könnte Stoff für ein
            Gedicht sein.
          </Paragraph>
          <Paragraph>
            Dass es kein Gedicht ist, erkennt man vor allem am regelmäßigen,
            geordneten Satzbau und daran, dass es wie ein Bericht klingt.
          </Paragraph>
          <PaperStack>
            <Paragraph>
              Am Wochenende steht ein eher kühler, windiger und wolkiger
              Nordwesten einem sommerlichen Süden gegenüber. In der neuen Woche
              ist es <NotationSingle>zunächst</NotationSingle> auch im Süden
              kühler, <NotationSingle>anschließend</NotationSingle> geht es aber
              beim Wetter <NotationSingle>insgesamt</NotationSingle> bergauf.
            </Paragraph>
          </PaperStack>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
